<template>
  <v-card class="mx-auto mt-4">
    <v-sheet
      class="mx-auto v-sheet--offset"
      :color="color"
      elevation="4"
      max-width="calc(100% - 32px)"
    >
      <v-sparkline
        :fill="false"
        :line-width="0"
        color="white"
        auto-line-width
        :padding="10"
        :smooth="20"
        :value="values"
        auto-draw
        height="60"
        show-labels
      ></v-sparkline>
    </v-sheet>
    <v-card-title
      class="primaryCyan--text overline font-weight-black pt-0 pb-6"
      style="line-height: 0px"
    >
      {{ title }}
    </v-card-title>
    <v-card-text>
      <v-row class="space-between">
        <v-col>
          <span class="text-h4 font-weight-light">{{ sumValues }}</span>
        </v-col>
        <v-col
          cols="auto"
          align-self="end"
          class="success--text"
          v-if="percentValuesLastWeek"
        >
          <v-icon small class="success--text"> mdi-arrow-top-right </v-icon>
          <small class="overline font-weight-black">{{ info }}</small>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: ["value", "color", "title"],
  computed: {
    values() {
      let arr = [];
      this.value.forEach((element) => {
        arr.push(element.count);
      });
      if (arr.length < 2) arr.unshift(0);

      return arr;
    },
    sumValues() {
      let sumValues = 0;
      this.value.forEach((element) => {
        sumValues += element.count;
      });
      return sumValues;
    },
    percentValuesLastWeek() {
      return "";
    },
  },
};
</script>
<style>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>
