<template>
  <div>
    <v-row>
      <v-col cols="6">
        <v-row>
          <v-col cols="6">
            <Kpi color="red" :value="contents" title="Conteúdos"></Kpi>
          </v-col>
          <v-col cols="6">
            <Kpi color="orange" :value="customers" title="Clientes"></Kpi>
          </v-col>
          <v-col cols="6">
            <Kpi color="green" :value="terminals" title="Terminais"></Kpi>
          </v-col>
          <v-col cols="6">
            <Kpi color="cyan" :value="playlists" title="Playlsits"></Kpi>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="6">
        <v-card>
          <v-card-title class="primaryCyan--text overline font-weight-black">
            Status Terminais
            <v-spacer></v-spacer>
            <v-btn fab x-small color="primaryCyan" @click="refreshTerminal()">
              <v-icon class="white--text">mdi-refresh</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row class="justify-center">
              <v-col cols="auto" class="text-center">
                <v-btn
                  fab
                  color="green"
                  class="d-block elevation-2"
                  @click="changeTerminalFilter(terminalsOn)"
                >
                  <v-icon color="white"> mdi-television </v-icon>
                </v-btn>
                <span class="d-block text-h6 mt-3" style="line-height: 18px">
                  {{ terminalsOn.data?.length }}
                </span>
                <span class="overline" style="line-height: 0px">Online</span>
              </v-col>

              <v-col cols="auto" class="text-center">
                <v-btn
                  fab
                  color="red"
                  class="d-block elevation-2"
                  @click="changeTerminalFilter(terminalsOff)"
                >
                  <v-icon color="white"> mdi-television-off </v-icon>
                </v-btn>
                <span class="d-block text-h6 mt-3" style="line-height: 18px">
                  {{ terminalsOff.data?.length }}
                </span>
                <span class="overline" style="line-height: 0px">Offline</span>
              </v-col>

              <v-col cols="auto" class="text-center">
                <v-btn
                  fab
                  color="warning"
                  class="d-block elevation-2"
                  @click="changeTerminalFilter(terminalsIssued)"
                >
                  <v-icon color="white"> mdi-television-pause </v-icon>
                </v-btn>
                <span class="d-block text-h6 mt-3" style="line-height: 18px">
                  {{ terminalsIssued.data?.length }}
                </span>
                <span class="overline" style="line-height: 0px">Defeito</span>
              </v-col>
              <v-col cols="auto" class="text-center">
                <v-btn
                  fab
                  color="info"
                  class="d-block elevation-2"
                  @click="changeTerminalFilter(terminalsIssued)"
                >
                  <v-icon color="white"> mdi-update </v-icon>
                </v-btn>
                <span class="d-block text-h6 mt-3" style="line-height: 18px">
                  {{ terminalsNotUpdated.data?.length }}
                </span>
                <span class="overline" style="line-height: 0px">Desatu..</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-simple-table dense fixed-header height="300px">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Nome</th>
                        <th class="text-left">Ultima atualização</th>
                        <th class="text-left"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in selectedTerminalFilter.data"
                        :key="item._id"
                      >
                        <td>{{ item.name }}</td>
                        <td>{{ moment(item.updatedAt) }}</td>
                        <td>
                          <v-chip
                            v-if="item.status === 'on'"
                            class="green"
                            outlined
                          >
                            <v-icon class="green--text" dark small>
                              mdi-television
                            </v-icon>
                          </v-chip>
                          <v-chip
                            v-if="item.status === 'off'"
                            class="red"
                            outlined
                          >
                            <v-icon class="red--text" dark small>
                              mdi-television-off
                            </v-icon>
                          </v-chip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Kpi from "./_components/kpi";
import contentService from "../contents/service/index";
import customersService from "../customers/service/index";
import playlistsService from "../playlists/service/index";
import terminalsService from "../terminal/service/index";
import moment from "moment";
export default {
  data: () => ({
    contents: [],
    customers: [],
    terminals: [],
    playlists: [],
    terminalsOn: [],
    terminalsOff: [],
    terminalsIssued: [],
    selectedTerminalFilter: [],
  }),
  components: {
    Kpi,
  },

  methods: {
    changeTerminalFilter(filter) {
      this.selectedTerminalFilter = filter;
    },
    moment(date) {
      moment.locale("pt-BR");
      return moment(date).fromNow();
    },
    async refreshTerminal() {
      try {
        this.terminalsOn = await terminalsService.getTerminals({
          status: "on",
        });
        this.selectedTerminalFilter = this.terminalsOn;
      } catch (error) {
        this.terminalsOn.data = [];
      }
      try {
        this.terminalsOff = await terminalsService.getTerminals({
          status: "off",
        });
      } catch (error) {
        this.terminalsOff.data = [];
      }
      try {
        this.terminalsIssued = await terminalsService.getTerminals({
          status: "issued",
        });
      } catch (error) {
        this.terminalsIssued.data = [];
      }
      try {
        this.terminalsNotUpdated = await terminalsService.getTerminals();
        console.log(this.terminalsNotUpdated);
      } catch (error) {
        this.terminalsNotUpdated.data = [];
      }
    },
  },

  async mounted() {
    this.contents = await contentService.getContentsByWeek();
    this.customers = await customersService.getCustomersByWeek();
    this.terminals = await terminalsService.getTerminalsByWeek();
    try {
      this.terminalsOn = await terminalsService.getTerminals({ status: "on" });
      this.selectedTerminalFilter = this.terminalsOn;
    } catch (error) {
      this.terminalsOn.data = [];
    }
    try {
      this.terminalsOff = await terminalsService.getTerminals({
        status: "off",
      });
    } catch (error) {
      this.terminalsOff.data = [];
    }
    try {
      this.terminalsIssued = await terminalsService.getTerminals({
        status: "issued",
      });
    } catch (error) {
      this.terminalsIssued.data = [];
    }
    try {
      this.terminalsNotUpdated = await terminalsService.getTerminals();
      this.terminalsNotUpdated.data = this.terminalsNotUpdated.data.filter(
        (item) => {
          if (item.updated === false) {
            return item;
          }
        }
      );
    } catch (error) {
      this.terminalsNotUpdated.data = [];
    }
    this.playlists = await playlistsService.getPlaylistsByWeek();
  },
};
</script>
<style>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>
